<!--  -->
<template>
  <div class="">
    <div class="ssq-table">
      <div class="ssq-table-header">
        <div class="ssq-icon">
          <svg
            t="1629609108732"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2399"
            width="45"
            height="45"
          >
            <path
              d="M452.846933 52.986311h117.771378l-127.724089 392.436622h-117.543822z"
              fill="#322C2B"
              p-id="2400"
            ></path>
            <path
              d="M29.641956 446.839467l36.189866-111.889067 333.969067 242.591289-36.420267 111.741155z"
              fill="#0C7CBE"
              p-id="2401"
            ></path>
            <path
              d="M273.965511 971.013689l-95.274667-69.290667 333.7216-242.212978 95.379912 69.048889z"
              fill="#EFB918"
              p-id="2402"
            ></path>
            <path
              d="M847.798044 900.616533l-95.277511 69.1456-127.374222-392.220444 95.152356-69.304889z"
              fill="#14934A"
              p-id="2403"
            ></path>
            <path
              d="M958.196622 333.348978l36.161422 111.746844H581.808356l-36.411734-111.746844z"
              fill="#D7282A"
              p-id="2404"
            ></path>
          </svg>
          <div class="ssq-one">
            <h2>中国体育彩票</h2>
          </div>
          <div class="ssq-two">
            <h3>七 星 彩 位 选 投 注 表</h3>
          </div>
          <div class="clear"></div>
        </div>
        <div class="clear"></div>
      </div>
      <div class="wei1">(位选)点击号码查看金额</div>
      <div class="wei0">
        <div style="width: 100%; float: left">
          <div class="wei">第一位:</div>
          <div
            :class="xred4.indexOf(i) == -1 ? 'weiball' : 'red'"
            v-for="(item, i) in 10"
            :key="i"
            @click="ball(3, i)"
          >
            {{ item - 1 }}
          </div>
        </div>
        <div style="width: 100%; float: left">
          <div class="wei">第二位:</div>
          <div
            :class="xred5.indexOf(i) == -1 ? 'weiball' : 'red'"
            v-for="(item, i) in 10"
            :key="i"
            @click="ball(4, i)"
          >
            {{ item - 1 }}
          </div>
        </div>
        <div style="width: 100%; float: left">
          <div class="wei">第三位:</div>
          <div
            :class="xred1.indexOf(i) == -1 ? 'weiball' : 'red'"
            v-for="(item, i) in 10"
            :key="i"
            @click="ball(0, i)"
          >
            {{ item - 1 }}
          </div>
        </div>
        <div style="width: 100%; float: left">
          <div class="wei">第四位:</div>
          <div
            :class="xred2.indexOf(i) == -1 ? 'weiball' : 'red'"
            v-for="(item, i) in 10"
            :key="i"
            @click="ball(1, i)"
          >
            {{ item - 1 }}
          </div>
        </div>
        <div style="width: 100%; float: left">
          <div class="wei">第五位:</div>
          <div
            :class="xred3.indexOf(i) == -1 ? 'weiball' : 'red'"
            v-for="(item, i) in 10"
            :key="i"
            @click="ball(2, i)"
          >
            {{ item - 1 }}
          </div>
        </div>
        <div style="width: 100%; float: left">
          <div class="wei">第六位:</div>
          <div
            :class="xred6.indexOf(i) == -1 ? 'weiball' : 'red'"
            v-for="(item, i) in 10"
            :key="i"
            @click="ball(5, i)"
          >
            {{ item - 1 }}
          </div>
        </div>
        <div style="width: 100%; float: left">
          <div class="wei">第七位:</div>
          <div
            :class="xred7.indexOf(i) == -1 ? 'weiball' : 'red'"
            v-for="(item, i) in 15"
            :key="i"
            @click="ball(6, i)"
          >
            {{ item - 1 }}
          </div>
        </div>
        <div class="clear"></div>
      </div>
      <div
        class="jine"
        @click="del()"
        style="
          border: 1px solid #c0c0c0;
          width: 80px;
          margin: 0 auto;
          margin-top: 20px;
        "
      >
        清空
      </div>
      <div class="jine">
        金额：{{
          xred1.length *
          xred2.length *
          xred3.length *
          xred4.length *
          xred5.length *
          xred6.length *
          xred7.length *
          2
        }}元
      </div>
    </div>
  </div>
</template>

<script>
//import axios from 'axios'
//import '../js/font.js' //js文件引入，注意路径
//import {myfun} from '../js/test.js' //js文件引入，注意路径
//import Introduction from '@/views/Introduction.vue'
export default {
  name: "qlc-basic",
  components: {},
  data() {
    // 数据源
    return {
      xred: [],
      xred1: [],
      xred2: [],
      xred3: [],
      xred4: [],
      xred5: [],
      xred6: [],
      xred7: [],
    };
  },
  mounted() {
    this.toTop();
  },
  computed: {},
  methods: {
    // 函数体部分，js的主要逻辑控制
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    mon(min, max, wangs, langs) {
      var s = 1;
      for (var n = min - 1; n < max; n++) {
        s = s * (n + 1);
      }
      for (var n1 = wangs; n1 > 0; n1--) {
        s = s / n1;
      }
      return s * langs * 2;
    },
    MO(a, b) {
      return Math.abs(a - b);
    },
    //号码点击选择函数
    ball(x, i) {
      if (x == 0) {
        if (this.xred1.indexOf(i) == -1) {
          this.xred1.push(i);
          // this.arr0.push(i + 1);
        } else {
          this.xred1.splice(this.xred1.indexOf(i), 1);
          // this.arr0.splice(this.arr0.indexOf(i + 1), 1);
        }
      } else if (x == 1) {
        if (this.xred2.indexOf(i) == -1) {
          this.xred2.push(i);
          // this.arr0.push(i + 1);
        } else {
          this.xred2.splice(this.xred2.indexOf(i), 1);
          // this.arr0.splice(this.arr0.indexOf(i + 1), 1);
        }
      } else if (x == 2) {
        if (this.xred3.indexOf(i) == -1) {
          this.xred3.push(i);
          // this.arr0.push(i + 1);
        } else {
          this.xred3.splice(this.xred3.indexOf(i), 1);
          // this.arr0.splice(this.arr0.indexOf(i + 1), 1);
        }
      } else if (x == 3) {
        if (this.xred4.indexOf(i) == -1) {
          this.xred4.push(i);
          // this.arr0.push(i + 1);
        } else {
          this.xred4.splice(this.xred4.indexOf(i), 1);
          // this.arr0.splice(this.arr0.indexOf(i + 1), 1);
        }
      } else if (x == 4) {
        if (this.xred5.indexOf(i) == -1) {
          this.xred5.push(i);
          // this.arr0.push(i + 1);
        } else {
          this.xred5.splice(this.xred5.indexOf(i), 1);
          // this.arr0.splice(this.arr0.indexOf(i + 1), 1);
        }
      } else if (x == 5) {
        if (this.xred6.indexOf(i) == -1) {
          this.xred6.push(i);
          // this.arr0.push(i + 1);
        } else {
          this.xred6.splice(this.xred6.indexOf(i), 1);
          // this.arr0.splice(this.arr0.indexOf(i + 1), 1);
        }
      } else if (x == 6) {
        if (this.xred7.indexOf(i) == -1) {
          this.xred7.push(i);
          // this.arr0.push(i + 1);
        } else {
          this.xred7.splice(this.xred7.indexOf(i), 1);
          // this.arr0.splice(this.arr0.indexOf(i + 1), 1);
        }
      }
    },
    del() {
      this.xred1 = [];
      this.xred2 = [];
      this.xred3 = [];
      this.xred4 = [];
      this.xred5 = [];
      this.xred6 = [];
      this.xred7 = [];
    },
  },
  props: [],
  watch: {},
};
</script>

<style scoped>
/* @import './swiper.css'; */
/* * {
  border: 1px solid red;
} */
.ssq-table-header {
  float: left;
  width: 100%;
  height: 50px;
  background-color: rgb(224, 202, 5);
}

.tab {
  margin: 0 auto;
  border-collapse: collapse;
}

.ssq-icon {
  text-align: left;
}

.icon {
  float: left;
}

.ssq-one {
  float: left;
  height: 42px;
  line-height: 42px;
  color: #fff;
}

.ssq-two {
  float: left;
  width: 80%;
  text-align: center;
  line-height: 42px;
  font-size: 25px;
  color: rgb(252, 251, 249);
}

tbody .xuan {
  background-color: rgb(160, 156, 156);
  border-radius: 15px;
  color: rgb(253, 253, 253);
}
.wei0 {
  width: 46%;
  margin-left: 35%;
  font-size: 15px;
}
.wei1 {
  width: 100%;
  margin: 0 auto;
  font-size: 15px;
}
.wei {
  float: left;
  margin-top: 30px;
  font-size: 15px;
}
.weiball {
  float: left;
  margin-top: 30px;
  margin-left: 10px;
  width: 25px;
  height: 25px;
  font-size: 14px;
  line-height: 25px;
  background-color: rgb(180, 173, 173);
  border-radius: 15px;
  color: #fff;
}
.red {
  float: left;
  margin-top: 30px;
  margin-left: 10px;
  width: 25px;
  height: 25px;
  font-size: 14px;
  line-height: 25px;
  background-color: rgb(250, 8, 8);
  border-radius: 15px;
  color: #fff;
}
.jine {
  font-size: 15px;
  margin-top: 20px;
}
.no {
  display: none;
}
</style>